<template>
  <CCardBody>
    <TheAside :id="itemId" @updated="getAllItems" />

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2 d-flex flex-column flex-gap-2">
          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Country Filter -->
            <div class="w-100">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                :value="$store.state.filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="countryFilter"
              />
            </div>

            <!-- Restaurant Filter -->
            <div class="w-100">
              <v-select
                class="v-select-filter"
                placeholder="Filter restaurants by.."
                v-model="selectedRestaurantsFilter"
                :options="[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                  { value: 'today-closed', label: 'Today closed' },
                  { value: 'temporarily-closed', label: 'Temporarily closed' },
                  { value: 'converted', label: 'Converted' },
                  { value: 'exclusive', label: 'Exclusive' },
                  { value: 'unsigned-contract', label: 'Unsigned contract' },
                ]"
                :reduce="o => o.value"
                multiple
                :searchable="false"
                @input="setDefaultFilter(false)"
              />
            </div>

            <!-- Restaurant Filter AND -->
            <div class="input-group flex-nowrap w-100">
              <label class="input-group-text select-label" for="resto-and-filter">AND</label>
              <v-select
                id="resto-and-filter"
                class="v-select-filter w-100 v-select-label"
                placeholder="Filter restaurants by.."
                v-model="selectedRestaurantsANDFilter"
                :options="[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                  { value: 'today-closed', label: 'Today closed' },
                  { value: 'temporarily-closed', label: 'Temporarily closed' },
                  { value: 'converted', label: 'Converted' },
                  { value: 'exclusive', label: 'Exclusive' },
                  { value: 'unsigned-contract', label: 'Unsigned contract' },
                ]"
                :reduce="o => o.value"
                :searchable="false"
                @input="setDefaultFilter(false)"
              />
            </div>
          </div>

          <!-- RESTAURANT TAG FILTER -->
          <fieldset class="border rounded p-1 bg-light">
            <legend class="w-auto bg-light py-1 px-2 resto-tag-title">
              Restaurant TAG
            </legend>
            <div class="d-flex flex-lg-row flex-column flex-gap-2">
              <!-- Week Filter -->
              <div class="w-100">
                <v-select
                  class="v-select-filter-week"
                  placeholder="-- Choose a period in week --"
                  v-model="selectedPeriodWeeks"
                  :options="periodWeeks"
                  :reduce="o => o.value"
                  :searchable="false"
                  @input="setDefaultFilter(true)"
                />
              </div>

              <!-- TAG Filter -->
              <div class="w-100">
                <v-select
                  class="v-select-filter"
                  placeholder="-- Choose a Restaurant TAG --"
                  v-model="selectedRestoTag"
                  :options="restoTags"
                  :reduce="o => o.value"
                  :searchable="false"
                  @input="setDefaultFilter(false)"
                />
              </div>

              <!-- Restaurants Filter -->
              <div class="w-100">
                <v-select
                  class="v-select-filter"
                  placeholder="Select Restaurant.."
                  v-model="selectedRestaurantFilter"
                  :options="restaurants"
                  @input="setDefaultFilter(false)"
                />
              </div>
            </div>
          </fieldset>

          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Delivery Service Filter -->
            <div class="w-100">
              <v-select
                class="v-select-filter"
                placeholder="🚚 Delivery service.."
                label="name"
                v-model="selectedHubs"
                :options="hubs"
                :searchable="false"
                :reduce="hub => hub.id"
                multiple
                @input="setDefaultFilter(false)"
              >
                <template v-slot:option="hub">
                  <template v-if="hub.id !== -1">
                    <strong>{{ hub.zip }}</strong> -
                    <span>{{ hub.name }} </span>
                  </template>
                </template>
              </v-select>
            </div>

            <!-- Originator Filter -->
            <div class="w-100">
              <v-select
                class="v-select-filter"
                placeholder="Originator.."
                v-model="selectedOriginator"
                :options="$globalVar.originators.filter(o => o.value !== null)"
                :reduce="c => c.value"
                :searchable="false"
                @input="setDefaultFilter(false)"
              />
            </div>

            <!-- Location Filter -->
            <div class="w-100">
              <v-select
                class="v-select-filter"
                placeholder="Location.."
                label="zcCity"
                v-model="selectedLocations"
                :filterable="false"
                :options="locations"
                :reduce="zc => ({id: zc.id, zcZip: zc.zcZip, zcCity: zc.zcCity})"
                :loading="loading"
                @search="onSearchLocation"
                @input="setDefaultFilter(false)"
                multiple
              >
                <template v-slot:option="zip_code">
                  <strong>{{ zip_code.zcZip }}</strong> -
                  <span>{{ zip_code.zcCity }} </span>
                </template>
                <template slot="selected-option" slot-scope="zip_code">
                  <strong class="text-dark mr-1">{{ zip_code.zcZip }}</strong>
                  <span>{{ zip_code.zcCity }} </span>
                </template>
                <span slot="no-options">Type to get locations..</span>
              </v-select>
            </div>
          </div>

          <div class="d-flex flex-lg-row flex-column flex-gap-2">
            <!-- Search -->
            <div class="w-100">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Type i#Id for Restaurant, u#Id for User, v#XX for VAT or anything else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      size="sm"
      clickable-rows
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No restaurants found!',
      }"
      @row-clicked="rowClicked"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >

      <!-- Check Box -->
      <template #select="{item}">
        <td class="no-print">
          <CInputCheckbox
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <!-- id -->
      <template #id="{item}">
        <td>
          <CLink @click="showAside(item.id)">
            {{ item.id }}
          </CLink>
        </td>
      </template>

      <!-- Prev. / Next Count -->
      <template #prev_rest_next_rest="{item}">
        <td class="no-print">
          <CLink v-if="item.prev_rest" @click="showAside(item.prev_rest)" class="d-inline count bg-dark px-1">{{ item.prev_rest }}</CLink>
          <b v-else>&empty;</b> /
          <CLink v-if="item.next_rest" @click="showAside(item.next_rest)" class="d-inline count bg-dark px-1">{{ item.next_rest }}</CLink>
          <b v-else>&empty;</b>
        </td>
      </template>

      <!-- Restaurant Device -->
      <template #restaurant_device="{item}">
        <td class="no-print">
          <span class="badge notify badge-secondary border border-dark" v-html="checkDeviceType(item)"></span>
          <CIcon
            :height="32"
            :class="checkLinkedDevice(item)"
            name="cil-mobile"
            v-c-tooltip="{
              html: true,
              content: checkLinkedDeviceLastCheckTime(item)
            }"
          />
        </td>
      </template>

      <!-- Restaurant Logo -->
      <template #restaurant_logo="{item}">
        <td>
          <CLink @click="showAside(item.id)">
            <CImg
              :src="getValidImageUrl(item.restaurant_logo_path)"
              class="c-avatar-img"
              style="width: 36px; height: 36px"
              placeholderColor="lightgray"
            />
          </CLink>
        </td>
      </template>

      <!-- Opening Hours  -->
      <template #restaurant_opening_hours="{item}">
        <td class="text-center no-print">
          <!-- isRestaurantOpenNow -->
          <CIcon
            :height="32"
            :class="isRestaurantOpenNow(item) ? 'text-success' : 'text-danger'"
            name="cil-clock"
            v-c-tooltip="{
              html: true,
              content: getRestaurantOpeningTime(item)
            }"
          />
        </td>
      </template>

      <!-- Name -->
      <template #restaurant_name="{item}">
        <td>
          <CLink
            v-show="item.restaurant_slug"
            :href="item.restaurant_slug_path | addUriScheme"
            target="_blank"
            v-c-tooltip="{content: item.restaurant_slug_path}"
            ><CIcon name="cil-restaurant"
          /></CLink>
          &nbsp;
          <CLink @click="showAside(item.id)">
            <strong>{{ item.restaurant_name }}</strong>
          </CLink>
        </td>
      </template>

      <!-- Location -->
      <template #restaurant_postcode="{item}">
        <td>
          <strong>{{item.restaurant_city }}</strong>
          <small class="d-block">{{item.restaurant_postcode }}</small>
        </td>
      </template>

      <!-- By -->
      <template #user_name="{item}">
        <td>
          <CLink :to="{name: 'User', params: { id: item.user.id }}" v-c-tooltip="{content : `# ${item.user.id}`}">
            {{ item.user.first_name }}
          </CLink>
        </td>
      </template>

      <!-- Balance -->
      <template #account_balance="{item}">
        <td :class="item.account ? (item.account.balance >= 0 ? 'text-danger' : 'text-success') : ''" class="text-center no-print">
          <b>{{ item.account ? item.account.balance : '&empty;' | toCurrency }}</b>
        </td>
      </template>

      <!-- Menu / Cat. Count -->
      <template #menu_count_category_count="{item}">
        <td class="no-print">
          <CLink class="d-inline count bg-dark px-1">{{ item.menus_count }}</CLink> /
          <CLink class="d-inline count bg-dark px-1">{{ item.categories_count }}</CLink>
        </td>
      </template>

      <!-- Orders -->
      <template #restaurant_orders_count="{item}">
        <td class="no-print">
          <CLink class="count bg-dark w-75 px-1">{{ item.restaurant_orders_count }}</CLink>
        </td>
      </template>

      <!-- Menu / Rel. -->
      <template #menu_active_relation_active="{item}">
        <td class="text-center no-print">
          <CBadge :color="item.menu_active == 1 ? 'success' : 'danger'" class="badge-first">{{ item.menu_active == 1 ? 'Yes' : 'No' }}</CBadge>
          <CBadge :color="item.relation_active == 1 ? 'success' : 'danger'">{{ item.relation_active == 1 ? 'Yes' : 'No' }}</CBadge>
        </td>
      </template>

      <!-- Subweb -->
      <template #subweb="{ item }">
        <td class="text-center no-print">
          <CLink
            v-show="item.website_etenonline_be"
            :href="item.website_etenonline_be | addUriScheme"
            target="_blank"
            v-c-tooltip="{content: item.website_etenonline_be}"
            ><CIcon name="cil-external-link"
          /></CLink>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <td class="text-center no-print">
          <CButton
            :to="{ name: 'Edit Restaurant', params: { id: item.id } }"
            v-c-tooltip="{
              content: 'Edit',
              placement: 'top',
            }"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-align-left" />
          </CButton>

          <CButton
            :to="{ name: 'View Restaurant', params: { id: item.id } }"
            v-c-tooltip="{
              content: 'View',
              placement: 'top',
            }"
            size="sm"
            color="primary"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-restaurant" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <!-- Modals -->
    <CModal class="copy-restaurant"
      title="Copy Restaurant Features"
      color="info"
      :show.sync="copyFeaturesModal"
      :closeOnBackdrop="false"
      centered
    >
      <CAlert show color="warning" class="p-2">
        <CIcon :height="24" name="cil-warning" class="mr-2" />
        <span>This operation will copy the category, menu and menu templates of restaurant
          <strong>{{ selectedItemLabel }}</strong>
          to the <strong>{{ selectedRestaurant ? selectedRestaurant.label : 'target' }}</strong> restaurant.
        </span>
      </CAlert>

      <div class="form-group">
        <label class="d-block" style="font-weight: 500">Target Restaurant:</label>
        <v-select
          class="v-select-filter"
          placeholder="Select restaurant.."
          v-model="selectedRestaurant"
          :options="allRestaurants"
        />
      </div>

      <CInputCheckbox
        label="Also copy menu images"
        custom
        :checked.sync="copyMenuImages"
      />

      <template #footer class="p-1">
        <CButton @click="copyFeaturesModal = false" color="link">Cancel</CButton>
        <CButton @click="copyFeatures" :disabled="selectedRestaurant === null" size="sm" color="info">
          <CIcon name="cil-double-quote-sans-right" /> Copy Features
        </CButton>
      </template>
    </CModal>

    <CModal class="copy-restaurant"
      :title="`${$options.filters.ucfirst(copyEvent)} Restaurant`"
      color="info"
      :show.sync="copyRestaurantModal"
      :closeOnBackdrop="false"
      centered
    >
      <CAlert show color="warning" class="p-2">
        <CIcon :height="24" name="cil-warning" class="mr-2" />
        <span>
          The restaurant will be <strong>{{ copyEvent }}d</strong>.
          Do you really want to continue?
        </span>
      </CAlert>

      <CInputCheckbox
        label="Also copy menu images"
        custom
        :checked.sync="copyMenuImages"
      />

      <template #footer class="p-1">
        <CButton @click="copyRestaurantModal = false" color="link">Cancel</CButton>
        <CButton @click="copyRestaurant(copyEvent + 'd')" size="sm" color="info">
          <CIcon :name="`cil-${copyEvent == 'clone' ? 'clone' : 'copy'}`" />
          {{ copyEvent | ucfirst }} Restaurant
        </CButton>
      </template>
    </CModal>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div>
            <CDropdown
              color="success"
              size="sm"
              :disabled="selectedItem === null"
            >
              <template #toggler-content>
                <CIcon name="cil-copy" /> Copy Restaurant
              </template>
              <CDropdownItem @click="() => { copyEvent = 'replicate'; copyRestaurantModal = true }">
                <CIcon name="cil-copy" class="mt-1 mr-1"/>Replicate (new btw)
              </CDropdownItem>
              <CDropdownItem @click="() => { copyEvent = 'clone'; copyRestaurantModal = true }">
                <CIcon name="cil-clone" class="mt-1 mr-1"/>Clone (multiple same resto)
              </CDropdownItem>
            </CDropdown>
          </div>

          <div>
            <CButton
              @click="copyFeaturesModal = true"
              color="info"
              size="sm"
              :disabled="selectedItem === null"
            >
              <CIcon name="cil-double-quote-sans-right" /> Copy Restaurant Features
            </CButton>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import TheAside from './TheAside'
import _debounce from 'lodash/debounce';
import { mapState } from 'vuex'

export default {
  name: "BackendTable",
  components: {
    TheAside
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "select",                    label: "",            filter: false, sorter: false, _classes:"no-print", _style: "width: 1%" },
        { key: "actions",                   label: "",            filter: false, sorter: false, _classes:"no-print", _style: "min-width: 80px; text-align: center; padding: 0.5rem 1.5rem" },

        { key: "restaurant_logo",           label: "",            filter: false, sorter: false },
        { key: "id",                        label: "ID",          filter: false },
        { key: "prev_rest_next_rest",       label: "Prev./Next",  filter: false, _classes:"no-print" },
        { key: "restaurant_device",         label: "Device",      filter: false, _classes:"no-print", _style: "text-align: center; max-width: 85px; padding-right: 0.5rem !important;" },
        { key: "restaurant_opening_hours",  label: "Opening",     filter: false, _classes:"no-print", _style: "text-align: center; max-width: 85px; padding-right: 0.5rem !important;" },
        { key: "restaurant_name",           label: "Name",        filter: false, _style: "min-width: 150px;" },
        { key: "restaurant_postcode",       label: "Location",    filter: false, _style: "min-width: 100px;"},
        { key: "user_name",                 label: "By",          filter: false, _style: "min-width: 100px;" },
        { key: "created_at",                label: "Created",     filter: false },
        { key: "account_balance",           label: "Balance",     filter: false, _classes:"no-print", _style: "text-align:center" },
        { key: "menu_count_category_count", label: "Menu/Cat.",   filter: false, _classes:"no-print" },
        { key: "restaurant_orders_count",   label: "Orders",      filter: false, _classes:"no-print" },
        { key: "menu_active_relation_active", label: "Menu/Rel.", filter: false, _classes:"no-print", _style: "text-align: center; max-width: 85px; padding-right: 0.5rem !important;" },
        { key: "subweb",                    label: "Subweb",      filter: false, _classes:"no-print", _style: "text-align: center; max-width: 85px; padding-right: 0.5rem !important;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Aside
      itemId: 0,

      // Alert
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Filter
      selectedRestaurantsFilter: ['active','inactive'],
      selectedRestaurantsANDFilter: [],
      locations: [],
      selectedLocations: [],
      selectedOriginator: undefined,

      // Restaurants replicate (clone)
      selectedItem: null,

      // Copy Restaurant
      copyRestaurantModal: false,
      copyFeaturesModal: false,
      copyMenuImages: true,
      copyEvent: null,
      allRestaurants: [],
      selectedRestaurant: null,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,

      // Weeks Filter
      periodWeeks: [],
      selectedPeriodWeeks: null,

      // Restaurant TAGS -> LT = Less Than, A = Above
      selectedRestoTag: null,
      restoTags: [
        { value: 'zero', label: 'ZERO' },
        { value: 'lt-ten', label: 'LT-TEN' },
        { value: 'lt-twenty', label: 'LT-TWENTY' },
        { value: 'lt-thirty', label: 'LT-THIRTY' },
        { value: 'lt-forty', label: 'LT-FORTY' },
        { value: 'a-fifty', label: 'A-FIFTY' },
      ],

      // Restaurant Filter
      restaurants: [],
      hubs: [],

      callFirst: true
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  created() {
    this.calcWeeks();
  },

  async mounted() {
    // Aside gizle
    this.$store.state.restaurantsAsideShow = false;

    this.activePage =  this.queryPage;
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }

    await this.getHubs();
    await this.getRestaurants();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Restaurants', query: { page: this.activePage } });

      this.onTableChange();
    },
    async copyFeaturesModal(newVal, oldVal) {
      if (newVal) {
        this.allRestaurants.length == 0 && await this.getAllRestaurants();
        this.selectedRestaurant = null;
        this.allRestaurants = this.allRestaurants.filter(item => item.value != this.selectedItem.id);
        this.copyMenuImages = true;
      }
    },
    copyRestaurantModal(newVal, oldVal) {
      this.copyMenuImages = newVal;
    }
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    selectedItemLabel() {
      return this.selectedItem ?
        `#${this.selectedItem.id} ${this.selectedItem.restaurant_postcode} ${this.selectedItem.restaurant_name}`
        : 'source'
    },

    // Filters
    selectedRestaurantFilter: {
      get() { return this.filters.selectedRestaurant },
      set(value) { this.filters.selectedRestaurant = value },
    },
    selectedHubs: {
      get() { return this.filters.restaurants.selectedHubs },
      set(value) { this.filters.restaurants.selectedHubs = value },
    },
  },

  methods: {
    calcWeeks() {
      let d = new Date();
      let d2 = new Date(d.getFullYear(), d.getMonth(), this.getFirstSaturday(d));
      let d3 = new Date(d2);
      d3.setDate(d2.getDate() - 6);

      let weeks = ["Zondag","Maandag","Dinsdag","Woensdag","Donderdag","Vrijdag","Zaterdag"];
      let y = d.getFullYear();
      let y2 = 2017;

      for(let i = 0; i < ((y - y2) * 52) + 1; i++){
        if(i === 0) {
          this.selectedPeriodWeeks = {text: weeks[0] + " " + d3.toLocaleDateString('nl') + " -- " + weeks[6] + " " + d2.toLocaleDateString('nl')}
        }
        this.periodWeeks.push({value:{text: weeks[0] + " " + d3.toLocaleDateString('nl') + " -- " + weeks[6] + " " + d2.toLocaleDateString('nl')}, label: weeks[0] + " " + d3.toLocaleDateString('nl') + " -- " + weeks[6] + " " + d2.toLocaleDateString('nl')})
        d2.setDate(d2.getDate() - 7);
        d3.setDate(d3.getDate() - 7);
      }
    },

    getFirstSaturday(date) {
      var saturday = date.getDate() - date.getDay() + 6;
      var firstSaturday = (saturday < 1) ? saturday + 7 : saturday + 0;
      return firstSaturday;
    },

    async getRestaurants() {
      const storedRestaurants = sessionStorage.getItem('restaurants');

      if (storedRestaurants) {
        this.restaurants = JSON.parse(storedRestaurants);
      } else {
        try {
          const response = await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME);
          this.restaurants = response.data;
          sessionStorage.setItem('restaurants', JSON.stringify(this.restaurants));
        } catch (error) {
          this.restaurants = [];
        }
      }
    },
    async getHubs() {
      this.hubs = JSON.parse(localStorage.getItem('hub-list-with-ds')) || [];

      await this.$axios
        .get(this.$backend.COURIER.HUBS.GET_LIST)
        .then(({ data }) => {
          this.hubs = [{ id: -1, name: 'With Delivery Service' }, ...data];
          localStorage.setItem('hub-list-with-ds', JSON.stringify(this.hubs));
        })
        .catch(() => {
          this.hubs = [{ id: -1, name: 'With Delivery Service' }];
        });
    },

    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    getAllItems(weekFilter) {
      if(weekFilter && this.selectedRestoTag == null && !this.callFirst) {
        return;
      }
      this.loading = true;
      let datumVan;
      let datumTot;

      if(this.selectedPeriodWeeks != null && this.selectedRestoTag != null) {
        let helper = this.selectedPeriodWeeks.text.substring(this.selectedPeriodWeeks.text.indexOf(" ") + 1, this.selectedPeriodWeeks.text.indexOf(" -")).replace('-', '/');
        let helper2 = this.selectedPeriodWeeks.text.substring(this.selectedPeriodWeeks.text.indexOf("- ") + 10).replace('-', '/');
        datumVan = this.formatDate(new Date(helper.substring(helper.indexOf('-') + 1), parseInt(helper.substring(helper.indexOf('/') + 1, helper.indexOf('-'))) - 1, helper.substring(0, helper.indexOf('/'))));
        datumTot = this.formatDate(new Date(helper2.substring(helper2.indexOf('-') + 1), parseInt(helper2.substring(helper2.indexOf('/') + 1, helper2.indexOf('-'))) - 1, helper2.substring(0, helper2.indexOf('/'))));
        console.log('selected datumVan: ',datumVan);
        console.log('selected datumTot: ',datumTot);
      }

      var url = new URL(this.$backend.RESTAURANT.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          filter: this.selectedRestaurantsFilter,
          filterAnd: this.selectedRestaurantsANDFilter,
          country: this.$store.state.filters.country,
          locations: this.selectedLocations.map(z => z.zcZip),
          originator: this.selectedOriginator,
          'restaurant-id': this.selectedRestaurantFilter?.value,
          date_from: datumVan,
          date_to: datumTot,
          tag: this.selectedRestoTag,
          hubs: this.selectedHubs
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            // items.data.forEach((item) => {
            //   item._classes = 'bg-danger';
            //   this.loadedItems.push(item);
            // });
            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = (item.temp_closed == 1 || item.rest_closed_today == 1) ? 'table-danger' : '';
              item._classes += item.none_active == 1 ? ' strikeout' : '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
          if(this.callFirst) {
            this.callFirst = false;
          }
        });
    },
    getValidImageUrl(url) {
      const ORIGIN_CDN = process.env.VUE_APP_ORIGIN_CDN;

      return url
        ? this.$globalFunc.isURL(url)
          ? url
          : ORIGIN_CDN + url
        : "";
    },
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    rowClicked (item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check (item) {
      this.$set(item, "_selected", !item._selected);
      // v1
      // item._classes = item._selected ? 'table-selected' : '';

      // v2
      // item._classes = item._selected
      //   ? item._classes.concat(" table-selected")
      //   : item._classes.replace(" table-selected", "");

      // v3
      if (item._selected) {
        // Sadece bir adet item seçilsin.
        if(this.selectedItem) {
          this.selectedItem._selected = false;
          this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
        }

        item._classes = item._classes.concat(" table-selected");
        this.selectedItem = item;
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItem = null;
      }
    },

    showAside(itemId) {
      this.itemId = itemId;
      this.$store.commit('toggle', 'restaurantsAsideShow');
    },

    // Filters
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getAllItems();
    },
    onSearchLocation(search, loading) {
      if (search.length) {
        loading(true);
        this.getLocations(loading, search, this);
      }
    },
    getLocations: _debounce((loading, search, vm) => {
      vm.$axios.get(vm.$backend.ZIP_CODES.SEARCH, {
        params: {
          country: vm.$store.state.filters.country,
          fields: 'zcZip,zcCity',
          q: search,
        },
      })
        .then((response) => (vm.locations = response.data))
        .finally(() => { loading(false); });
    }, 1000),
    setDefaultFilter(weekFilter) {
      this.getAllItems(weekFilter);
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.selectedRestaurantsFilter = ['active','inactive'];
        this.selectedRestaurantsANDFilter = [];
        this.selectedLocations = [];
        this.selectedOriginator = undefined;
        this.search = "";
        this.selectedRestaurantFilter = undefined;
        this.periodWeeks = [];
        this.selectedPeriodWeeks = null;
        this.selectedRestoTag = null;
        this.selectedHubs = [];
        this.calcWeeks();
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    copyRestaurant(event) {
      this.copyRestaurantModal = false;
      this.loading = true;
      this.$axios
        .post(this.$backend.RESTAURANT.REPLICATE, {
          restaurant_id: this.selectedItem.id,
          event: event,
          'copy-menu-images': this.copyMenuImages
        })
        .then((response) => {
          this.getAllItems();
          this.showErrorAlert = false;
          this.successAlertMessage = {
            itemName: "Success:",
            message: response.data.success,
          };
          this.dismissSuccessAlert = this.dismissSecs;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
          window.scrollTo(0, 0);

          this.selectedItem._selected = false;
          this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
          this.selectedItem = null
          this.selectedRestaurant = null
        });
    },

    copyFeatures() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to copy restaurant features?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.copyFeaturesModal = false;
            this.loading = true;
            this.$axios.post(this.$backend.RESTAURANT.COPY_FEATURES, {
              'restaurant_id':this.selectedItem.id,
              'target':this.selectedRestaurant.value,
              'copy-menu-images': this.copyMenuImages
            })
              .then((response) => {
                this.showErrorAlert = false
                this.successAlertMessage = { itemName: 'Success:', message: response.data.success };
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                this.loading = false;
                window.scrollTo(0,0);

                this.selectedItem._selected = false;
                this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
                this.selectedItem = null
                this.selectedRestaurant = null
              });
            // ---------------
          }
        },
      });
    },

    async getAllRestaurants() {
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;
        })
        .catch(() => { this.allRestaurants = [] })
    },

    //#region From eo-main
    // checkopeningstime(restaurant)
    isRestaurantOpenNow(restaurant) {
      var now = new Date();
      var today = now.getDay();

      var yesterday = today - 1;
      if (yesterday < 0) yesterday = 6;

      var hours = restaurant.open_sunday;
      var hours_yesterday = restaurant.open_sunday;

      switch (today) {
        case 1: // monday
          hours = restaurant.open_monday;
          break;
        case 2:
          hours = restaurant.open_tuesday;
          break;
        case 3:
          hours = restaurant.open_wednesday;
          break;
        case 4:
          hours = restaurant.open_thursday;
          break;
        case 5:
          hours = restaurant.open_friday;
          break;
        case 6:
          hours = restaurant.open_saturday;
          break;
      }

      switch (yesterday) {
        case 1: // monday
          hours_yesterday = restaurant.open_monday;
          break;
        case 2:
          hours_yesterday = restaurant.open_tuesday;
          break;
        case 3:
          hours_yesterday = restaurant.open_wednesday;
          break;
        case 4:
          hours_yesterday = restaurant.open_thursday;
          break;
        case 5:
          hours_yesterday = restaurant.open_friday;
          break;
        case 6:
          hours_yesterday = restaurant.open_saturday;
          break;
      }

      if (hours != undefined && hours != "") {
        var arr = hours.split(",");
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("-");
          // Check if the location is open
          //console.log("open: "+arr2[0]+", close: "+arr2[1]);

          if (this.checkOpenHours(now, now, arr2[0], arr2[1])) {
            //console.log(restaurant.id + " open calced");
            return true;
          }
        }
      }

      if (hours_yesterday != undefined && hours_yesterday != "") {
        var yesterday_date = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 1,
          0,
          0,
          0,
          0
        );

        var arr = hours.split(",");
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("-");
          // Check if the location is open
          //console.log("open: "+arr2[0]+", close: "+arr2[1]);

          if (this.checkOpenHours(now, yesterday_date, arr2[0], arr2[1])) {
            //console.log(restaurant.id + " open by yesterday calced");
            return true;
          }
        }
      }

      return false;
    },

    checkOpenHours(now, operatingDate, open, close) {
      if ((open != undefined && open != "") || (close != undefined && close != "")) {
        // Splitting times to array
        var o = open.split(":");
        var c = close.split(":");

        // Hours not in proper format
        if (o.length < 2 || c.length < 2) {
          return false;
        }

        // Converting array values to int
        for (var i = 0; i < o.length; i++) {
          o[i] = parseInt(o[i]);
        }
        for (var i = 0; i < c.length; i++) {
          c[i] = parseInt(c[i]);
        }

        // Set opening Date()
        var od = new Date(
          operatingDate.getFullYear(),
          operatingDate.getMonth(),
          operatingDate.getDate(),
          o[0],
          o[1],
          0,
          0
        );

        // Set closing Date()
        var closingDay = operatingDate.getDate();

        // Closing after midnight, shift day to tomorrow
        if (o[0] > c[0]) {
          closingDay++;
        }
        var cd = new Date(
          operatingDate.getFullYear(),
          operatingDate.getMonth(),
          closingDay,
          c[0],
          c[1],
          0,
          0
        );

        // Is within operating hours?
        if (now > od && now < cd) {
          return true;
        } else return false;
      } else return false;
    },

    getRestaurantOpeningTime(restaurant) {
      var now = new Date();
      var today = now.getDay();

      var hours = restaurant.open_sunday;

      switch (today) {
        case 1: // monday
          hours = restaurant.open_monday;
          break;
        case 2:
          hours = restaurant.open_tuesday;
          break;
        case 3:
          hours = restaurant.open_wednesday;
          break;
        case 4:
          hours = restaurant.open_thursday;
          break;
        case 5:
          hours = restaurant.open_friday;
          break;
        case 6:
          hours = restaurant.open_saturday;
          break;
      }

      return (hours !== null && hours !== "")
        ? `<div style='width: 75px'>${hours.replace(",", "<br />")}</div>`
        : "<div style='width: 140px'>No opening hours yet!</div>";
    },

    checkLinkedDevice(restaurant) {
      var $device_link = restaurant.restaurant_device_link_with_device;
      if ($device_link) {
        if ($device_link.lastcheck) {
          const momentLastConn = this.moment($device_link.lastcheck, "YYYY/MM/DD hh:mm:ss"),
            day_diff = this.moment().diff(momentLastConn, "days"),
            minute_diff = this.moment().diff(momentLastConn, "minutes");

          var checkTime = 5;

          if($device_link.device.ws_status) {
            checkTime = 20;
          }

          return minute_diff <= checkTime
            ? "bg-success text-light rounded-circle p-1" // Last check in 5 minutes
            : day_diff <= 2
              ? "bg-warning text-light rounded-circle p-1"
              : "bg-danger text-light rounded-circle p-1";
        }
        return 'text-danger'; // No last check time yet!
      }
      return 'text-dark'; // No device linked yet!
    },

    checkLinkedDeviceLastCheckTime(restaurant) {
      var $device_link = restaurant.restaurant_device_link_with_device;
      return $device_link
        ? $device_link.lastcheck
          // ? `<div style='width: 75px'>${$device_link.lastcheck}</div>`
          ? ($device_link.device.ws_status == 1
           ? `<div style='width: 75px'>${restaurant._hr_datetime} from WS</div>`
           : `<div style='width: 75px'>${restaurant._hr_datetime}</div>`)
          : "<div style='width: 140px'>No last check time yet!</div>"
        : "<div style='width: 140px'>No device linked yet!</div>";
    },

    checkDeviceType: function (restaurant) {
      var $device_link = restaurant.restaurant_device_link_with_device;

      if ($device_link != null && $device_link.device != null)
        return `${$device_link.device.software ? 'MS' : 'MB'}-<span class="${$device_link.device.ws_status != 1 ? "text-danger" : "text-success"} font-weight-bold">${$device_link.device.ws_status != 1 ? 'OFF' : 'ON'}</span><span class="d-block">${$device_link.device.version != null ? $device_link.device.version : ''}</span>`;

      return '∅';
    },
    //#endregion
  },
};
</script>

<style>
.table th, .table td {
  vertical-align: middle;
}

/* table-filter */
@media (min-width: 576px) {
  label.mfe-2 + input[type="text"] {
    width: 70% !important;
  }
}
.copy-restaurant .modal-header {
  padding: 0.75rem !important;
}
.copy-restaurant .modal-footer {
  padding: 0.25rem !important;
}
</style>

<style scoped>
.badge {
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0;
}
.badge-first {
  border-right: 1px solid #fff;
}
.badge.notify {
  position: absolute;
  left: 25px;
  top: 10px;
  border-radius: 30px;
}
.resto-tag-title {
  font-weight: 500;
  font-size: 1rem;
  border-radius: 0.375rem;
}
.select-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #3c3c3c42;
  font-weight: bold;
}
</style>
